    // $primary: #a51616;
    $primary: #bf160a;
    /* MAIN COLOR */
    
    $secondary: #ffba1c;
    /* SECONDARY COLOR */
    
    $trm: #ffd45b;
    /*TRIM COLOR*/
    
    $blk: #333;
    /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
    
    $wht: #fafafa;
    $border-radius: 0px;
    /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
    
    $footerLinks: #fafafa;
    .container {
        @media (max-width: 1440px) {
            width: 100% !important;
        }
        @media (max-width: 1024px) {
            width: 100% !important;
        }
        @media (max-width: 767px) {
            width: 100% !important;
        }
    }
    
    .top-pad {
        @media (max-width: 767px) {
            margin-top: 100px;
        }
    }
    
    .flash {
        display: none;
    }
    
    body {
        background: url('../img/backdrop-bg.png');
        background-repeat: repeat;
        background-attachment: fixed;
    }
    
    .wrapper {
        padding-left: 150px !important;
        @media (max-width: 767px) {
            padding-left: 0px !important;
        }
    }
    
    .sticky-wrapper,
    .is-sticky {
        height: auto !important;
    }
    
    .navbar {
        margin: 0px;
        border: 0px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    
    a,
    h1,
    h2,
    h3 {
        font-family: 'Passion One', cursive;
        letter-spacing: 1px;
    }
    
    p,
    ol,
    ul {
        font-family: 'Josefin Sans', sans-serif;
    }
    
    nav {
        z-index: 10;
    }
    
    .row {
        margin-left: 0px;
        margin-right: 0px;
    }
    
    .navbar-brand {
        background: url('../img/logo.png');
        background-repeat: no-repeat;
        margin-top: 20px;
        width: 150px;
        height: 150px;
        margin-left: 0px;
        @media (max-width: 767px) {
            background: url('../img/logo-small.png');
            background-repeat: no-repeat;
            width: 189px;
            height: 70px;
            margin: 0px;
        }
    }
    
    .navbar-nav {
        margin-top: 25px;
        text-align: left;
        float: none;
        @media (max-width: 767px) {
            text-align: center;
            margin-top: 0px;
        }
    }
    
    .navbar-fixed-left {
        width: 150px !important;
        position: fixed;
        border-radius: 0;
        height: 100%;
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#d76f0f+0,ffffff+100 */
        background: rgb(215, 111, 15);
        /* Old browsers */
        background: -moz-linear-gradient(top, rgba(215, 111, 15, 1) 0%, rgba(255, 255, 255, 1) 100%);
        /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(215, 111, 15, 1) 0%, rgba(255, 255, 255, 1) 100%);
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(215, 111, 15, 1) 0%, rgba(255, 255, 255, 1) 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        border-right: 2px solid $secondary;
        @media (max-width: 767px) {
            width: 100% !important;
            height: 75px;
            position: fixed;
            border-radius: 0;
            border: 0px;
            box-shadow: 0px 0px 0px 3px rgba(0, 0, 0, 0.2);
            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,f4901e+100 */
            background: rgb(255, 255, 255);
            /* Old browsers */
            background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(244, 144, 30, 1) 100%);
            /* FF3.6-15 */
            background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(244, 144, 30, 1) 100%);
            /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(244, 144, 30, 1) 100%);
            /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            margin-top: 0px;
            z-index: 1;
        }
    }
    
    .navbar-header {
        @media (max-width: 767px) {
            background: $primary;
            height: 80px;
        }
    }
    
    // .navbar-fixed-left-overlay {background-image: url('../img/lower-banner-bg-3.jpg');
    // 		 background-repeat: repeat;
    // 		     opacity: 0.2;
    // 							height: 100%;
    // 							margin: 0px;
    // }
    .navbar-right {
        float: none;
        margin-right: -40px;
        @media (max-width: 767px) {
            margin-right: 25px;
        }
    }
    
    .navbar-toggle {
        margin: 18px 15px 8px 0px;
        border: 1px solid transparent;
        border-radius: 4px;
        @media (max-width: 388px) {
            margin-top: 20px;
        }
        @media (max-width: 767px) {
            padding: 10px;
        }
    }
    
    .navbar-fixed-left .navbar-nav>li {
        float: none;
        /* Cancel default li float: left */
        width: 240px;
        font-size: 2em;
        height: 35px;
        @media (max-width: 767px) {
            text-align: center;
            height: 37px;
            display: inline-block;
            align-items: center;
            width: 100%;
        }
    }
    
    .navbar-fixed-left .navbar-nav>li>a {
        margin-top: 5px;
        color: $wht;
        font-weight: bold;
        text-shadow: 1px 1px $primary;
        padding-left: 80px;
        outline: none;
        text-align: left;
        @media (max-width: 767px) {
            height: 45px;
            display: block;
            padding-left: 0px;
            font-size: .75em;
            margin-left: 15px;
            margin-top: 0px;
        }
        &:focus,
        &:active {
            background: transparent;
            color: $wht;
            outline: 0;
        }
        &:hover {
            background: transparent;
            color: $secondary;
            @media (max-width: 1024px) {
                background: transparent;
                color: $secondary;
                outline: 0;
            }
        }
    }
    
    .navbar-collapse {
        width: 100%;
        //   margin-top: 18px;
        background: transparent;
        padding-right: 0px;
        padding-left: 0px;
        @media (max-width: 767px) {
            border-bottom: 2px solid $primary;
            padding-left: 15px;
            padding-right: 15px;
            background-color: $primary !important;
        }
    }
    
    // /* On using dropdown menu (To right shift popuped) */
    // .navbar-fixed-left .navbar-nav > li > .dropdown-menu {
    //   margin-top: -50px;
    //   margin-left: 140px;
    // }
    .logo {
        @media (max-width: 767px) {
            width: 100%;
            display: block;
            margin: 0 auto;
            max-width: 115px;
            padding-top: 15px;
        }
    }
    
    .modal-open {
        overflow: auto;
        padding-right: 0px !important;
    }
    /* ========= BUTTONS ============ */
    
    .btn-download,
    .btn-download:focus,
    .btn-download:active,
    .btn-download:visited {
        background-color: $secondary;
        border-color: $wht;
        color: $primary;
        padding: 10px 20px;
        font-size: 19px;
        border-radius: 5px;
        transition: all 1s;
        -webkit-transition: all 1s;
        -moz-transition: all 1s;
        -o-transition: all 1s;
    }
    
    .btn-download:hover {
        background-color: $primary;
        color: $wht;
    }
    
    .btn-download {
        margin-bottom: 15px !important;
        display: block;
        // margin: 0 auto;
        max-width: 175px;
        margin-top: 15px;
    }
    
    .btn-download-2,
    .btn-download-2:focus,
    .btn-download-2:active,
    .btn-download-2:visited {
        background-color: $secondary;
        border-color: $wht;
        color: $primary;
        padding: 10px 20px;
        font-size: 19px;
        border-radius: 5px;
        transition: all 1s;
        -webkit-transition: all 1s;
        -moz-transition: all 1s;
        -o-transition: all 1s;
    }
    
    .btn-download-2:hover {
        background-color: $primary;
        color: $wht;
    }
    
    .btn-download-2 {
        margin-bottom: 15px !important;
        display: block;
        margin: 0 auto;
        max-width: 175px;
        margin-top: 15px;
    }
    /* =========== HEADER ========== */
    
    .header {
        background-image: url('../img/banner.jpg');
        background-repeat: no-repeat;
        background-position: left;
        background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        -webkit-background-size: cover;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        @media (max-width: 1440px) {
            background-position: left;
            padding: 10px 10px;
        }
        @media (max-width: 1199px) {
            background-position: left;
            padding: 10px 10px;
        }
        @media (max-width: 1025px) {
            background-position: left;
            background-attachment: scroll;
            padding: 10px 10px;
            text-align: left;
        }
        @media (max-width: 767px) {
            background-position: left;
            padding: 10px 10px;
            text-align: center;
        }
    }
    
    .header-overlay {
        background-color: rgba(31, 31, 31, 0.5);
        padding: 165px 0px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        @media (max-width: 1440px) {
            padding: 15px 0px;
            margin-top: 0px;
        }
        @media (max-width: 767px) {
            padding: 15px 0px;
            border: 1px solid #fff;
            margin-top: 80px;
        }
    }
    
    .header h1 {
        color: $wht;
        text-shadow: 2px 2px $secondary;
        font-size: 5em;
        @media (max-width: 1024px) {
            font-size: 4em;
        }
        @media (max-width: 767px) {
            font-size: 2.5em;
        }
    }
    
    .header p {
        color: $wht;
        font-size: 1.75em;
        line-height: 45px;
        letter-spacing: 2px;
        @media (max-width: 767px) {
            font-size: 1.5em;
            line-height: 30px;
        }
    }
    
    .header img {
        width: 100%;
        display: block;
        margin: 0px auto;
        max-width: 550px;
        @media (max-width: 1024px) {
            max-width: 500px;
            margin-top: 50px;
        }
    }
    
    .header .col-md-6 {
        width: 50%;
        @media (max-width: 1280px) {
            width: 100%;
        }
    }
    
    .header .col-md-7 {
        padding-right: 45px;
        @media (max-width: 767px) {
            padding: 0px;
        }
    }
    
    .header .form-bg {
        background: rgba(217, 21, 8, 0.5);
        border-radius: 15px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        border: 1px solid #ccc;
    }
    
    .header ul {
        list-style: none;
        font-size: 3em;
        color: $wht;
        @media (max-width: 1024px) {
            display: none;
        }
        @media (max-width: 767px) {
            display: none;
        }
    }
    
    .header i {
        font-size: 30px;
        @media (max-width: 767px) {
            display: none;
        }
    }
    /* ========== SECTION ONE =============*/
    
    .section-one {
        background: transparent;
        padding: 0px;
        border-bottom: 5px solid $secondary;
        max-height: 1000px;
        overflow-y: hidden;
        @media (max-width: 1680px) {
            overflow-y: hidden;
        }
        @media (max-width: 1440px) {
            overflow-y: visible;
            max-height: 5000px;
            padding: 0px;
        }
    }
    
    .left-text {
        background: url('../img/img4.jpg');
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        -webkit-background-size: cover;
    }
    
    .col-md-6-overlay {
        background-color: rgba(201, 180, 146, 0.75);
        padding: 75px;
        min-height: 556px;
        @media (max-width: 1680px) {
            padding: 20px;
        }
        @media (max-width: 767px) {
            padding: 10px;
        }
    }
    
    .section-one h1 {
        color: $primary;
        font-size: 4em;
        text-shadow: 1px 1px $wht;
        padding-top: 15px;
        @media (max-width: 1024px) {
            font-size: 3em;
        }
    }
    
    .section-one .col-md-6 {
        padding: 0px;
    }
    
    .section-one .container-fluid {
        padding: 0px;
    }
    
    .section-one p {
        color: $primary;
        font-size: 1.75em;
        line-height: 45px;
        letter-spacing: 2px;
        @media (max-width: 767px) {
            font-size: 1.5em;
            line-height: 30px;
        }
    }
    
    .section-one img {
        display: block;
        margin: 0px auto;
        width: 100%;
    }
    
    .text-container {
        background: $secondary;
        padding: 25px;
        border-radius: 12px;
        border: 8px double $wht;
        box-shadow: inset 0 4px 8px 0 rgba(0, 0, 0, 0.2), inset 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    
    #signUpForm {
        margin-top: 35px;
    }
    
    .section-one .col-md-6 {
        width: 50%;
        @media (max-width: 1440px) {
            width: 100%;
        }
    }
    
    .black {
        background: $secondary;
    }
    /* ========== SECTION TWO =============*/
    
    .section-two {
        padding: 15px;
        @media (max-width: 1440px) {
            padding: 10px;
            background-position: 80% 50%;
        }
        @media (max-width: 767px) {
            padding: 10px;
            background-position: 70% 50%;
        }
    }
    
    .section-two-overlay {
        background-color: rgba(31, 31, 31, .75);
        padding: 50px 0px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        border: 1px solid #ccc;
        @media (max-width: 1024px) {
            padding: 25px 0px;
        }
        @media (max-width: 767px) {
            padding: 0px;
        }
    }
    
    .section-three .border {
        width: 100%;
        max-width: 360px;
        border: 1px solid $wht;
        border-radius: 12px;
        background-color: rgba(255, 255, 255, .5);
        margin-right: 15px;
        //   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        min-height: 30vh;
        padding: 15px;
        margin-top: 100px;
        @media (max-width: 1440px) {
            display: block;
            margin: 0 auto;
            margin-top: 50px;
            max-width: 100%;
        }
    }
    
    .section-three .border:last-child {
        margin-right: 0px;
        @media (max-width: 1024px) {
            display: block;
            margin: 0 auto;
            margin-top: 50px;
        }
    }
    
    .section-two h1 {
        color: $wht;
        font-size: 4em;
        text-shadow: 1px 1px $secondary;
        @media (max-width: 767px) {
            font-size: 3.5em;
        }
    }
    
    .section-two h2 {
        color: $wht;
        font-size: 2.5em;
        text-shadow: 1px 1px $secondary;
    }
    
    .section-two p {
        color: $wht;
        font-size: 1.75em;
        line-height: 40px;
        letter-spacing: 2px;
        @media (max-width: 767px) {
            font-size: 1.25em;
            line-height: 30px;
        }
    }
    
    .section-two img {
        width: 100%;
        display: block;
        margin: 0px auto;
        max-width: 850px;
        margin-top: 50px;
        border: 10px solid #fff;
        box-shadow: inset 0 4px 8px 0 rgba(0, 0, 0, 0.2), inset 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        @media (max-width: 1024px) {
            max-width: 300px;
            display: block;
            margin: 0px auto;
            width: 100%;
        }
    }
    
    .section-three i {
        color: $secondary;
        font-size: 125px;
        width: 100%;
        display: block;
        margin: 0 auto;
        max-width: 125px;
        margin-top: -85px;
    }
    /* ========== SECTION THREE =============*/
    
    .section-three {
        background: transparent;
        padding: 15px;
        text-align: center;
        @media (max-width: 1440px) {
            padding: 10px;
            text-align: center;
        }
    }
    
    .section-three-overlay {
        background-color: rgba(255, 255, 255, 0.95);
        padding: 50px 0px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        border: 1px solid #777;
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,a0743c+100 */
        background: rgb(255, 255, 255);
        /* Old browsers */
        background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(160, 116, 60, 1) 100%);
        /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(160, 116, 60, 1) 100%);
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(160, 116, 60, 1) 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }
    
    .section-three h1 {
        color: $primary;
        font-size: 4em;
        text-shadow: 1px 1px $secondary;
        @media (max-width: 1440px) {
            font-size: 3em;
        }
        @media (max-width: 767px) {
            font-size: 2em;
        }
    }
    
    .section-three h2 {
        font-size: 2.2em;
        color: $secondary;
        text-shadow: 1px 1px $primary;
        margin: 10px 0px;
    }
    
    hr {
        margin-top: 5px !important;
        margin-bottom: 15px !important;
    }
    
    .section-three p {
        color: $primary;
        font-size: 1.75em;
        line-height: 40px;
        letter-spacing: 2px;
        @media (max-width: 767px) {
            font-size: 1.25em;
            line-height: 30px;
        }
    }
    
    .section-three img {
        width: 100%;
        max-width: 350px;
        border: 4px solid $secondary;
        @media (max-width: 1199px) {
            max-width: 350px;
            display: block;
            margin: 0px auto;
            width: 100%;
            margin-bottom: 50px;
        }
    }
    
    .section-three .col-md-6 {
        width: 50%;
        @media (max-width: 1199px) {
            width: 100%;
        }
    }
    /* ========== SECTION FIVE =============*/
    
    .section-five {
        background: transparent;
        padding: 15px;
        @media (max-width: 1440px) {
            padding: 10px;
            text-align: center;
        }
    }
    
    .section-five-overlay {
        background-color: rgba(255, 255, 255, 0.95);
        padding: 50px 0px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        border: 1px solid $secondary;
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#d76f0f+0,ffffff+100 */
        background: rgb(215, 111, 15);
        /* Old browsers */
        background: -moz-linear-gradient(top, rgba(215, 111, 15, 1) 0%, rgba(255, 255, 255, 1) 100%);
        /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(215, 111, 15, 1) 0%, rgba(255, 255, 255, 1) 100%);
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(215, 111, 15, 1) 0%, rgba(255, 255, 255, 1) 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }
    
    .section-five h1 {
        color: $primary;
        font-size: 4em;
        text-shadow: 1px 1px $secondary;
        @media (max-width: 1440px) {
            font-size: 3em;
        }
        @media (max-width: 767px) {
            font-size: 2em;
        }
    }
    
    .section-five h3 {
        font-size: 3em;
        color: $secondary;
        text-shadow: 1px 1px $primary;
        margin: 10px 0px;
    }
    
    .section-five p {
        color: $blk;
        font-size: 1.75em;
        line-height: 45px;
        letter-spacing: 2px;
        margin-top: 25px;
        @media (max-width: 767px) {
            font-size: 1.25em;
            line-height: 30px;
        }
    }
    
    .section-five img {
        display: block;
        max-width: 600px;
        border: 4px solid $secondary;
        margin-top: 50px;
        @media (max-width: 1440px) {
            max-width: 350px;
            display: block;
            margin: 0px auto;
            width: 100%;
            display: none;
        }
    }
    
    .section-five .col-md-8 {
        width: 50%;
        @media (max-width: 1440px) {
            width: 100%;
        }
    }
    /* ========== SECTION SIX =============*/
    
    .section-six {
        padding: 15px;
        @media (max-width: 1440px) {
            padding: 10px;
            background-position: 80% 50%;
        }
        @media (max-width: 767px) {
            padding: 10px;
            background-position: 70% 50%;
        }
    }
    
    .section-six-overlay {
        background-color: rgba(31, 31, 31, .75);
        padding: 50px 0px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        border: 1px solid #ccc;
        @media (max-width: 1024px) {
            padding: 25px 0px;
        }
        @media (max-width: 767px) {
            padding: 0px;
        }
    }
    
    .section-six h1 {
        color: $wht;
        font-size: 4em;
        text-shadow: 1px 1px $secondary;
        @media (max-width: 767px) {
            font-size: 3.5em;
        }
    }
    
    .section-six h2 {
        color: $wht;
        font-size: 2.5em;
        text-shadow: 1px 1px $secondary;
    }
    
    .section-six p {
        color: $wht;
        font-size: 1.75em;
        line-height: 40px;
        letter-spacing: 2px;
        @media (max-width: 767px) {
            font-size: 1.25em;
            line-height: 30px;
        }
    }
    
    .section-six img {
        width: 100%;
        display: block;
        margin: 0px auto;
        max-width: 850px;
        margin-top: 50px;
        @media (max-width: 1024px) {
            max-width: 300px;
            display: block;
            margin: 0px auto;
            width: 100%;
        }
        @media (max-width: 1024px) {
            margin-top: 25px;
            margin-bottom: 50px
        }
    }
    /*=============== CTA =============== */
    
    .cta {
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#c9b492+0,ffffff+100 */
        background: rgb(201, 180, 146);
        /* Old browsers */
        background: -moz-linear-gradient(top, rgba(201, 180, 146, 1) 0%, rgba(255, 255, 255, 1) 100%);
        /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(201, 180, 146, 1) 0%, rgba(255, 255, 255, 1) 100%);
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(201, 180, 146, 1) 0%, rgba(255, 255, 255, 1) 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        padding: 0px;
        border-bottom: 5px solid $secondary;
    }
    
    .cta h1 {
        color: $wht;
        text-shadow: 1px 1px $primary;
    }
    /* ============ BUTTONS MODALS ============= */
    
    @mixin btn1 {
        text-align: center;
        margin: 0 auto;
        border: 1px solid $primary;
        border-radius: $border-radius;
        background: $primary;
        color: $wht;
        padding: 0.5em 2em;
    }
    
    .modal-dialog {
        max-width: 300px;
        text-align: center;
        margin: 6em auto;
        z-index: 1000;
        .close {
            display: none;
        }
        .modal-content {
            color: $primary;
            h2 {
                text-align: center;
                font-size: 2em;
            }
            a {
                font-size: .63em;
            }
        }
        input {
            border: none;
            border: 1px solid darken($primary, 20%);
            text-align: center;
        }
        button {
            background: transparent;
            color: $wht;
            display: block;
            border: none;
            padding: 1em 2em;
            margin: 0 auto;
            &:hover {
                background: transparent;
                color: #fff;
                box-shadow: none;
                text-shadow: 0px 0px 3px $blk;
                border: none;
            }
        }
        input[type="submit"] {
            @include btn1;
            display: block;
            width: 75%;
        }
    }
    
    .modal-header,
    .modal-footer {
        background: $primary;
        color: $wht;
    }
    
    footer {
        padding: 50px 0px 20px;
        background: $primary;
        color: $footerLinks;
        a {
            color: $wht !important;
            background: transparent !important;
            &:hover {
                color: $wht;
            }
        }
    }
    
    footer img {
        width: 100%;
        display: block;
        margin: 0 auto;
        max-width: 98px;
        padding-bottom: 20px;
    }
    
    select {
        -moz-appearance: none;
        text-indent: 0.01px;
        text-overflow: '';
    }
    
    .fade {
        background: rgba(0, 0, 0, 0.5) !important;
    }